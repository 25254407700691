<template>
  <div class="logout-content d-flex justify-center">
    Sikeres kijelentkezés.
  </div>
</template>

<script>

export default {
  name: 'logout',
};
</script>

<style scoped>
.logout-content {
  padding-top: 32px;
  font-size: 2em;
}
</style>
